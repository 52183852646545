import React from 'react';
import { Tag, Button, Tooltip } from 'antd';
import { Dropdown, Text } from 'components/basic';
import { DownOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
interface ColumnProps {
    trip: any;
}

const ColumnActions: React.FC<ColumnProps> = ({ trip }) => {
    const navigate = useNavigate();

    return (
        <Dropdown
            menu={{
                items: [
                    {
                        label: 'View',
                        key: '1',
                        onClick: () => {
                            navigate(`/trips/${trip.trip_id}`);
                        },
                    },
                ],
            }}
        >
            <Button>
                <DownOutlined />
            </Button>
        </Dropdown>
    );
};

export const columns = [
    {
        title: 'Trip ID',
        key: 'trip_id',
        render: (trip: any) => (
            <div className="truncate">
                <Text type="uuid" className="w-fit truncate">
                    {trip?.trip_id}
                </Text>
            </div>
        ),
    },
    {
        title: 'Requested At',
        key: 'requested_at',
        render: (trip: any) => (
            <div>
                {trip?.created_at ? (
                    <span className="underline decoration-dotted cursor-pointer">
                        {moment.utc(trip.created_at).add(8, 'hour').format('MMMM DD, YYYY hh:mm:ss A')}
                    </span>
                ) : (
                    <span>-</span>
                )}
            </div>
        ),
    },
    {
        title: 'Service Zone',
        key: 'service_zone',
        render: (trip: any) => <span>{trip?.service_zone}</span>,
    },
    {
        title: 'Status',
        key: 'status',
        render: (trip: any) => {
            return (
                <>
                    {trip?.status === 'complete' && (
                        <Tag color="green" className="uppercase whitespace-break-spaces">
                            {trip.status?.replaceAll('_', ' ')}
                        </Tag>
                    )}
                    {trip?.status == 'terminated' && (
                        <Tag color="red" className="uppercase whitespace-break-spaces">
                            {trip?.status?.replaceAll('_', ' ')}
                        </Tag>
                    )}
                    {(trip?.status == 'finding_driver' ||
                        trip?.status == 'driver_otw_pickup' ||
                        trip?.status == 'driver_arrived_pickup' ||
                        trip?.status == 'driver_otw_destination' ||
                        trip?.status == 'driver_arrived_destination') && (
                        <Tag color="geekblue" className="uppercase whitespace-break-spaces">
                            {trip?.status?.replaceAll('_', ' ')}
                        </Tag>
                    )}
                    {trip?.status === 'driver_not_found' && (
                        <Tag color="volcano" className="uppercase whitespace-break-spaces">
                            {trip?.status?.replaceAll('_', ' ')}
                        </Tag>
                    )}
                    {trip?.status?.indexOf('canceled') > -1 && (
                        <Tag color="red" className="uppercase whitespace-break-spaces">
                            {trip?.status?.replaceAll('_', ' ')}
                        </Tag>
                    )}
                </>
            );
        },
    },
    {
        title: 'Pick-up Location',
        key: 'start_location',
        render: (trip: any) => (
            <div>
                <span className="block">{trip?.pickup?.main}</span>
                <span className="block text-gray-400">{trip?.pickup?.secondary}</span>
            </div>
        ),
    },
    {
        title: 'Drop-off Location',
        key: 'dropoff_location',
        render: (trip: any) => (
            <div>
                <span className="block">{trip?.dropoff?.main}</span>
                <span className="block text-gray-400">{trip?.dropoff?.secondary}</span>
            </div>
        ),
    },
    {
        title: 'Fare',
        key: 'fare',
        render: (trip: any) => (
            <p className="whitespace-nowrap m-0 text-right">
                <span className="mr-1 text-gray-400">{trip?.price?.currency}</span>
                <span>{trip?.price?.final?.toFixed(2) || 0}</span>
            </p>
        ),
    },
    {
        title: '',
        render: (trip: any) => <ColumnActions trip={trip} />,
    },
];
