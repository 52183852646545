import { CustomNotification, Dropdown, Table, Text } from 'components/basic';
import ListLayout from 'components/layouts/ContentLayout/ListLayout';
import React, { useCallback, useContext, useState, useRef, useMemo, useEffect } from 'react';
import { Alert, Button } from 'antd';
import { getOperatorsList } from 'services/message.service';
import useApi from 'hooks/useApi';
import useFilter from 'hooks/useFilter';
import useMount from 'hooks/useMount';
import CreateOperatorModal from './CreateOperatorModal/CreateOperatorModal';
import useModal from 'hooks/useModal';
import { DownOutlined } from '@ant-design/icons';
import UpdateOperatorModal from './UpdateRoleModal/UpdateOperatorModal';
import UpdateStatusModal from './UpdateStatusModal/UpdateStatusModal';
import { Accessibility, PagesEnum, getPermissions } from 'services/permission.service';
import { AuthUserContext } from 'components/context/AuthUserContext';
import { pdf, drawDOM } from '@progress/kendo-drawing';
import { saveAs } from '@progress/kendo-file-saver';
import { columns, exportColumns } from './columns';
import moment from 'moment';

const OperatorsPage = () => {
    const { exportPDF } = pdf;
    const createOperatorModal = useModal();
    const [data, setData] = useState([]);
    const [exportData, setExportData] = useState([]);
    const [exporting, setExporting] = useState(false);
    const [pagination, setPagination]: any = useState({
        defaultCurrent: 1,
        defaultPageSize: 10,
    });
    const updateUserRoleModal = useModal();
    const updateStatusModal = useModal();
    const userRole = useContext(AuthUserContext);
    const hasActionAccess = (page: string) => {
        return getPermissions(userRole, page)?.includes(Accessibility.ALL);
    };

    const exportAccess = (page: string) => {
        return (
            getPermissions(userRole, page)?.includes(Accessibility.VIEW) ||
            getPermissions(userRole, page)?.includes(Accessibility.ALL)
        );
    };

    const [modifiedColumns, setModifiedColumns] = React.useState<any>(null);
    const { modifyFilters, requestState } = useFilter({
        page_size: 10,
        page: 1,
        search_by: '',
        search_key: '',
        sort_key: 'first_name',
        sort_by: 'asc',
    });

    const { request, loading } = useApi({
        api: getOperatorsList,
    });

    const fetchList = useCallback(
        async (requestState: {}, forExport?: boolean) => {
            try {
                const result = await request(requestState);
                let d = result.data;
                if (d.status == 'success') {
                    let operators = d.data.operators.map((c: any) => {
                        return {
                            ...c,
                            key: c.id,
                        };
                    });
                    if (!forExport) {
                        setData(operators);
                        let pagination = d.data.pagination;
                        let p = {
                            current: pagination.page,
                            defaultCurrent: 20,
                            total: pagination.total_count,
                            pageSize: pagination.page_size,
                        };
                        setPagination(p);
                    } else {
                        setExportData(operators);
                    }
                }
            } catch (error) {
                throw error;
            }
        },
        [request],
    );

    const exportList = useMemo(() => {
        return exportData.length > 0 ? exportData : data;
    }, [exportData, data]);

    const handleUpdateStatus = useCallback(
        (user: any) => {
            updateStatusModal.show({
                content: {
                    name: `${user.first_name} ${user.last_name}`,
                    currentRole: user.user_role,
                    email: user.email,
                    status: user.status,
                    id: user.id,
                    newStatus: user.status === 'ACTIVE' || user.status === 'active' ? 'DEACTIVATED' : 'ACTIVE',
                },
                refreshList: () => fetchList(requestState),
            });
        },
        [fetchList, requestState, updateStatusModal],
    );

    const ColumnActions = useCallback(
        (user: any) => {
            return (
                <Dropdown
                    menu={{
                        items: [
                            {
                                label: 'Edit Role',
                                key: '1',
                                onClick: () => {
                                    updateUserRoleModal.show({
                                        content: {
                                            name: `${user.first_name} ${user.last_name}`,
                                            currentRole: user.user_role,
                                            email: user.email,
                                        },
                                        refreshList: () => fetchList(requestState),
                                    });
                                },
                            },
                            {
                                label:
                                    user.status === 'active' || user.status === 'ACTIVE' ? (
                                        <Text className="text-red-600">Deactivate</Text>
                                    ) : (
                                        'Activate'
                                    ),
                                key: '2',
                                onClick: () => handleUpdateStatus(user),
                            },
                        ],
                    }}
                >
                    <Button type="ghost">
                        Actions
                        <DownOutlined />
                    </Button>
                </Dropdown>
            );
        },
        [fetchList, requestState, updateUserRoleModal, handleUpdateStatus],
    );

    useMount(() => {
        const modifyColumnsForSuperAdmin = async () => {
            if (hasActionAccess(PagesEnum.OPERATORS)) {
                setModifiedColumns([...columns, { title: '', render: (user: any) => ColumnActions(user) }]);
            } else {
                setModifiedColumns(columns);
            }
        };

        const initialize = async () => {
            await modifyColumnsForSuperAdmin();
            fetchList(requestState);
        };

        initialize();
    });

    const onTableChange = useCallback(
        async (pagination: any, filters: any, sorter: any) => {
            const { current, pageSize } = pagination;
            const { field, order } = sorter;
            const { requestState } = await modifyFilters({
                page_size: pageSize,
                page: current,
                sort_key: field,
                sort_by: order == 'ascend' ? 'asc' : 'desc',
            });
            await fetchList(requestState || {});
        },
        [fetchList, modifyFilters],
    );

    const handleOnShowModal = useCallback(() => {
        createOperatorModal.show();
    }, [createOperatorModal]);

    const prepareExportData = async () => {
        await fetchList({ ...requestState, page_size: pagination.pageSize * pagination.current, page: 1 }, true);
    };

    const waitForElement = (selector: string, timeout = 5000) => {
        return new Promise((resolve, reject) => {
            const interval = 1000; // Check every 100ms
            let timeElapsed = 0;

            const checkExist = setInterval(() => {
                const element = document.querySelector(selector);
                if (element) {
                    clearInterval(checkExist);
                    resolve(element); // Return the element
                } else if (timeElapsed >= timeout) {
                    clearInterval(checkExist);
                    reject(new Error('Element not found within timeout'));
                }
                timeElapsed += interval;
            }, interval);
        });
    };

    const onPDFExportClicked = async (e: any) => {
        setExporting(true);
        prepareExportData();
    };

    const generatePdf = useCallback(async () => {
        const options = { paperSize: 'A4', landscape: true };
        const table = await waitForElement('#datasource-operators');
        if (table instanceof HTMLElement) {
            const group = await drawDOM(table, {
                paperSize: options?.paperSize ?? 'Letter',
                landscape: options?.landscape ?? false,
                repeatHeaders: true,
                margin: {
                    left: 20,
                    top: 20,
                    right: 20,
                    bottom: 20,
                },
                scale: {
                    y: 0.75,
                    x: 0.75,
                },
            });
            exportPDF(group, options)
                .then(data => {
                    saveAs(data, `Operators-${moment().format('YYYY-MM-DD-HHmmss')}.pdf`);
                    CustomNotification({
                        type: 'success',
                        message: 'Success',
                        description: `Operators exported successfully.`,
                    });
                    setExporting(false);
                })
                .catch(e => console.error(e));
        } else {
            CustomNotification({
                type: 'error',
                message: 'Error',
                description: `Export Failed. Please try again.`,
            });
        }
    }, [exportPDF]);

    useEffect(() => {
        if (exporting) {
            generatePdf();
        }
    }, [exporting, generatePdf]);
    return (
        <ListLayout
            title="Operators"
            actionComponent={
                <div className="flex gap-2">
                    {hasActionAccess(PagesEnum.OPERATORS) && (
                        <Button type="primary" onClick={handleOnShowModal}>
                            Invite Operator
                        </Button>
                    )}
                    {exportAccess(PagesEnum.OPERATORS) && (
                        <Button onClick={onPDFExportClicked} loading={exporting}>
                            {exporting ? 'Exporting..' : 'Export to PDF'}
                        </Button>
                    )}
                </div>
            }
        >
            <Alert
                message={
                    <div>
                        To remove or update an operator,{' '}
                        <a href="https://app.clickup.com/14274986/v/fm/dkmda-12587" target="_blank">
                            request a ticket
                        </a>{' '}
                        through Click-Up
                    </div>
                }
                type="warning"
                showIcon
            />
            {!exporting ? (
                <Table
                    loading={loading}
                    columns={modifiedColumns}
                    dataSource={data}
                    pagination={{
                        ...pagination,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        showTotal: (total: any) => `${total?.toLocaleString()} items`,
                    }}
                    onChange={onTableChange}
                />
            ) : (
                <div>
                    <Text className="italic">Processing...</Text>
                    <div id="datasource-operators">
                        <Text fontWeight="font-semibold mb-md">{`Operators - Export Date: ${moment().format(
                            'YYYY-MM-DD-HHmmss',
                        )}`}</Text>
                        <Table columns={exportColumns} dataSource={exportList} pagination={false} />
                        <Text className="mt-md text-center">{`Operators: Page 1 - ${pagination.current} (${exportList.length} items)`}</Text>
                    </div>
                </div>
            )}
            <CreateOperatorModal
                modal={createOperatorModal}
                onSuccess={() => {
                    fetchList(requestState);
                }}
            />
            <UpdateOperatorModal {...updateUserRoleModal} />
            <UpdateStatusModal {...updateStatusModal} />
        </ListLayout>
    );
};

export default OperatorsPage;
